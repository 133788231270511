import { buildFiltersAppliedSearchTracking } from '~/helpers/search/searchTracking'
import type { Locale } from 'lc-services/types'

export const useSearchTracking = () => {
  const { trackEvent } = useTracking()
  const { locale } = useI18n()

  const { bathrooms, bedrooms, capacity } = useSearchNumericFilters()
  const { facetsFiltersParams } = useSearchFacetFilters()
  const { startDate, endDate } = useSearchDateFilters()
  const { minBudget, maxBudget } = useSearchBudgetFilters()
  const { queryValue } = useSearchLocationFilters()
  const { nbHits } = useSearchResults({ startDate, endDate })

  const filtersAppliedPayload = (
    searchableTagsIdentifiers: Record<string, { en: string; fr: string }>[],
  ) => {
    return buildFiltersAppliedSearchTracking({
      capacity: capacity.value,
      bedrooms: bedrooms.value,
      bathrooms: bathrooms.value,
      destinationId: queryValue.value?.value?.toString() || undefined,
      endDate: endDate.value,
      facetsFiltersParams: facetsFiltersParams.value,
      maxBudget: maxBudget.value,
      minBudget: minBudget.value,
      startDate: startDate.value,
      locale: locale.value as Locale,
      searchableTagsIdentifiers: searchableTagsIdentifiers || [],
    })
  }

  const trackSearchHouse = ({
    houseId,
    mapVisible,
    searchableTagsIdentifiers = [],
  }: {
    houseId: number
    mapVisible: boolean
    searchableTagsIdentifiers: Record<string, { en: string; fr: string }>[]
  }) => {
    const filtersApplied = filtersAppliedPayload(searchableTagsIdentifiers)
    const tracking = {
      category: 'Search',
      event: 'click_on_card',
      house_id: houseId,
      is_map_view: mapVisible,
      filters_applied: filtersApplied,
    }

    trackEvent(tracking)
  }

  const trackSearchResultsViewed = ({
    isCorrectHousePeriod,
    mapVisible,
    searchableTagsIdentifiers = [],
  }: {
    isCorrectHousePeriod?: boolean
    mapVisible: boolean
    searchableTagsIdentifiers: Record<string, { en: string; fr: string }>[]
  }) => {
    const filtersApplied = filtersAppliedPayload(searchableTagsIdentifiers)
    const tracking = {
      category: 'Search',
      event: 'search_results_viewed',
      number_of_results: nbHits.value,
      result_view: mapVisible ? 'map' : 'list',
      filters_applied: filtersApplied,
      ...(typeof isCorrectHousePeriod === 'boolean'
        ? { is_correct_house_period: isCorrectHousePeriod }
        : null),
    }

    trackEvent(tracking)
  }

  const trackSearchMap = ({ event }: { event: 'map_close' | 'map_open' }) => {
    const { query } = useRoute()
    const searchQuery = decodeURIComponent(
      new URLSearchParams(Object(query)).toString(),
    )

    trackEvent({
      category: 'Search',
      event,
      search_query: searchQuery,
    })
  }

  const trackFavoritePeriod = () => {
    const { query } = useRoute()
    trackEvent({
      category: 'General',
      event: 'click_period_update',
      search_query: decodeURIComponent(
        new URLSearchParams(Object(query)).toString(),
      ),
    })
  }

  return {
    trackFavoritePeriod,
    trackSearchHouse,
    trackSearchMap,
    trackSearchResultsViewed,
  }
}
