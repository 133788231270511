<script setup lang="ts">
import type { ReplicasValues } from '~/types/search/types'

const { t } = useI18n()
const { isSmallScreen } = useBreakpoint()

const emits = defineEmits<{
  'change-replica': [{ label: ReplicasValues; value: string }]
  'toggle-map': []
}>()

const props = withDefaults(
  defineProps<{
    isLoading?: boolean
    mapVisible: boolean
    searchResultsText: string
    currentReplica: string
    replicas: {
      label: ReplicasValues
      value: string
    }[]
    showBtnMapAndSort: boolean
  }>(),
  {
    isLoading: false,
  },
)

const options = computed(() => {
  return props.replicas.map((replica) => {
    return {
      label: t(`search.${replica.label}`),
      value: replica.value,
    }
  })
})

const defaultReplica =
  options.value.find((o) => o.value === props.currentReplica)?.value || null

const selectedReplica = ref(defaultReplica)

const changeReplica = (replica: string) => {
  const currentReplica = props.replicas.find((r) => r.value === replica)
  if (currentReplica) emits('change-replica', currentReplica)
}
const toggleMap = () => {
  emits('toggle-map')
}
</script>

<template>
  <div class="mb-4 flex items-center justify-between">
    <div v-if="isLoading" class="animate-wave h-4 w-[70px]" />
    <h2 v-else class="m-0 font-sansSerif text-xs font-medium text-gray-600">
      {{ searchResultsText }}
    </h2>

    <div v-if="showBtnMapAndSort" class="flex items-center">
      <template v-if="!mapVisible">
        <LazyBaseFormComponentsBaseMultiselect
          v-model="selectedReplica"
          :can-clear="false"
          :can-deselect="false"
          :height="24"
          :options="options"
          input-label="label"
          name="sortByClient"
          wrapper-class="multiselect-replica m-0"
          @select="changeReplica"
        >
          <template #multiselect-singlelabel="{ value }">
            <div class="multiselect-single-label">
              <span class="text-md">
                {{ $t('search.sort_by') }} : {{ value.label }}
              </span>
            </div>
          </template>

          <template #multiselect-option="{ option }">
            <span class="text-md">
              {{ option.label }}
            </span>
          </template>
        </LazyBaseFormComponentsBaseMultiselect>
      </template>

      <BaseButton
        v-if="!isSmallScreen"
        class="ml-4 !px-4 !py-3 text-md !font-normal"
        color="grey"
        variant="outline"
        has-icon
        @click="toggleMap"
      >
        <BaseIcon
          aria-hidden="true"
          class="mr-2.5"
          focusable="false"
          :name="mapVisible ? 'viewGrid' : 'map'"
        />
        <template v-if="mapVisible">{{ $t('search.list') }}</template>
        <template v-else>{{ $t('search.map') }}</template>
      </BaseButton>
    </div>
  </div>
</template>

<style>
.multiselect-replica .multiselect {
  @apply border-0;
  min-height: auto;
}
.multiselect-replica .multiselect-placeholder,
.multiselect-replica .multiselect-single-label {
  @apply static	px-2;
}
</style>
