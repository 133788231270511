<script setup lang="ts">
defineProps<{
  favoritePeriod: { percent: number; period: string }
  nearPeriods?: { previous: string; next: string }
}>()
defineEmits<{
  'click-favorite-period': ['previous' | 'next']
  'schedule-call': []
  'send-message': []
}>()
const localePath = useLocalePath()
</script>

<template>
  <div>
    <div class="sm:flex sm:flex-col sm:gap-4">
      <i18n-t
        class="sm:m-0"
        keypath="search.favoritePeriods.helpBox.text"
        tag="p"
      >
        <template #bold>
          <strong>
            {{ $t('search.favoritePeriods.helpBox.bold') }}
          </strong>
        </template>
        <template #period>
          <strong>
            {{ $t(`search.favoritePeriods.${favoritePeriod.period}`) }}
          </strong>
        </template>
        <template #bold2>
          <strong>
            {{ $t('search.favoritePeriods.helpBox.bold2') }}
          </strong>
        </template>
      </i18n-t>

      <template v-if="nearPeriods">
        <button
          class="period-selector mr-4 sm:mr-0"
          type="button"
          @click="$emit('click-favorite-period', 'previous')"
        >
          {{ nearPeriods.previous }}
        </button>
        <button
          class="period-selector"
          type="button"
          @click="$emit('click-favorite-period', 'next')"
        >
          {{ nearPeriods.next }}
        </button>
      </template>
    </div>

    <div class="my-6 h-px w-full bg-gray-300" />

    <div class="flex flex-col gap-4 sm:gap-6">
      <p class="m-0 text-md">{{ $t('search.needHelp') }}</p>

      <div class="flex flex-col gap-4">
        <BaseButton
          class="hover:!text-gray-300 focus:!border-none focus:!outline-none"
          color="black"
          has-icon
          no-padding
          variant="btn"
          @click="$emit('schedule-call')"
        >
          <BaseIcon name="phone" :size="1.25" />
          <span class="ml-2 text-md">{{ $t('search.scheduleACall.cta') }}</span>
        </BaseButton>
        <BaseButton
          color="black"
          has-icon
          no-padding
          variant="link"
          :to="localePath({ name: 'contact' })"
        >
          <BaseIcon name="mail" :size="1.3" />
          <span class="ml-2 text-md">{{ $t('search.sendAMessage') }}</span>
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<style scoped>
.period-selector {
  @apply w-fit rounded border border-gray-700 p-2 text-md font-semibold;
}
</style>
