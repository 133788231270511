<script setup lang="ts">
import type BaseCalendar from '~/components/BaseForm/components/BaseCalendar.vue'
import type { InputSearchQuery } from '~/types/search/types'

const emits = defineEmits<{
  'clear-query': []
  'dates-cleared': []
  'refine-by-query': [InputSearchQuery]
  'remove-option': [Partial<InputSearchQuery>]
  'toggle-filters-modal': []
  'update-budget': [string, string]
  'update:end-date': [Date]
  'update:start-date': [Date]
}>()

defineProps<{
  endDate: string | null
  favoritePeriod: { percent: number; period: string }
  isFavoritePeriodWeekly: boolean
  isLoading: boolean
  maxBudget: string
  minBudget: string
  multipeQuery: boolean
  queryValue: InputSearchQuery | InputSearchQuery[]
  startDate: string | null
}>()

const { setLock } = useLockBody()

const refineByQuery = (option: InputSearchQuery) =>
  emits('refine-by-query', option)
const updateBudget = (maxBudget: string, minBudget: string) => {
  emits('update-budget', maxBudget, minBudget)
}

const searchCalendarClientMobile = ref<InstanceType<typeof BaseCalendar>>()
const showCalendar = computed(
  () => searchCalendarClientMobile.value?.showCalendar || false,
)

watch(
  () => showCalendar.value,
  (newVal) => {
    setLock(newVal)
  },
)
</script>

<template>
  <div
    :class="[
      'sticky top-0 border-b border-gray-200 bg-white px-4 py-2.5',
      { 'z-[1500]': showCalendar, 'z-dropdown': !showCalendar },
    ]"
  >
    <SearchInput
      class="mb-2 w-full"
      :disabled="isLoading"
      :model-value="queryValue"
      :is-taggable="multipeQuery"
      @clear-query="emits('clear-query')"
      @refine-by-query="refineByQuery"
      @remove-option="emits('remove-option', $event)"
    />

    <LazyBaseFormComponentsBaseCalendar
      ref="searchCalendarClientMobile"
      disabled-days-before-day-date
      name="searchCalendar"
      has-header
      is-affixed
      wrapper-class="w-full mb-2"
      :disabled="isLoading"
      :check-in="startDate"
      :check-out="endDate"
      :placeholder="{
        checkIn: $t('calendar.check_in'),
        checkOut: $t('calendar.check_out'),
      }"
      @clear-dates="emits('dates-cleared')"
      @update:end-date="emits('update:end-date', $event)"
      @update:start-date="emits('update:start-date', $event)"
    >
      <template #lc-calendar-header-mobile="{ closeDatePicker }">
        <div class="mb-4 flex justify-end">
          <button
            class="texy-primary-400 text-md font-bold"
            type="button"
            @click="closeDatePicker"
          >
            {{ $t('global.close') }}
          </button>
          <DestinationFavoritePeriod
            v-if="isFavoritePeriodWeekly"
            class="fixed bottom-0 z-fixed mb-4 ml-4 text-xs"
            v-bind="favoritePeriod"
          />
        </div>
      </template>
    </LazyBaseFormComponentsBaseCalendar>

    <div class="flex">
      <SearchBudget
        class="grow"
        :disabled="isLoading"
        :max-budget="maxBudget"
        :min-budget="minBudget"
        @update-budget="updateBudget"
      />
      <SearchTopBarFiltersButton
        class="ml-2"
        :disabled="isLoading"
        @toggle-filters="$emit('toggle-filters-modal')"
      />
    </div>
  </div>
</template>
